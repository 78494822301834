import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import { auth, isLoggedIn, SignOutAndClearAllData, deleteAccount } from '../firebase/firebase-handler';
import { ModalInputWrapper, openInputField, closeInputField } from '../modal/ModalInput';
import { GetVersionNum } from '../util/VersionNumber';
import { CancelButton } from '../util/CancelButton';


const GamePage = () => {
    const initializedRef = useRef(false);
    const unityContainerRef = useRef(null);
    const unityCanvasRef = useRef(null);
    const [lastShakeTimestamp, setLastShakeTimestamp] = useState(0);
    
    const { unityProvider, loadingProgression, isLoaded, sendMessage, addEventListener, removeEventListener, requestFullscreen } = useUnityContext({
        loaderUrl: "unity_build/partytrivia-web-app.loader.js",
        dataUrl: "unity_build/partytrivia-web-app.data.unityweb",
        frameworkUrl: "unity_build/partytrivia-web-app.framework.js.unityweb",
        codeUrl: "unity_build/partytrivia-web-app.wasm.unityweb",

        streamingAssetsUrl: "StreamingAssets",
    });

    const onUnityLogOut = (json) => {
        console.log(`[onUnityLogOut] got logout request with the json: ${json}`);
        SignOutAndClearAllData();
    };

    const onUnityDeleteAccount = (json) => {
        console.log(`[onUnityDeleteAccount] got delete account request with the json: ${json}`);
        deleteAccount();
    };

    const onUnityUpdateJoincode = (json) => {
        var joincode = JSON.parse(json);
        localStorage.setItem("SavedJoinCode", joincode);
        console.log(`[onUnityUpdateJoincode] updated the joincode: ${localStorage.getItem("SavedJoinCode")}`);
    };

    const onUnityCopyToClipboard = (text) => {
        console.log(`[UnityCopyToClipboard] unity request to CopyToClipboard with the text: ${text}`);
        navigator.clipboard.writeText(text)
        .then(() => {
            console.log(`Copied text to clipboard: ${text}`);
            alert(`Copied text to clipboard: ${text}`);
        })
        .catch((error) => {
            console.error(`Could not copy text: ${error}`);
        });
    };

    const onUnityVibrate = (json) => {
        var value = JSON.parse(json);
        console.log(`[onUnityVibrate] unity request to vibrate with value: ${value}`);
        if (navigator.vibrate) {
            navigator.vibrate(value);
        }
    };

    const onUnityVibratePattern = (json) => {
        // json should be in for the format of = { "patternArray": int[], "length": int}
        var value = JSON.parse(json);
        var patternArray = value?.patternArray;
        var length = value?.length;
        console.log(`[onUnityVibrate] unity request to vibrate pattern with patternArray: ${patternArray}, with lenght: ${length}`);
        
        if (navigator.vibrate) {
            var pattern = Array.from({length: length}, (v, i) => module.HEAP32[patternArray / 4 + i]);
            navigator.vibrate(pattern);
        }
    };

    // Function to handle shaking detection
    const handleDeviceMotion = (event) => {
        const { accelerationIncludingGravity } = event;
        if (!accelerationIncludingGravity) return;

        const SHAKE_THRESHOLD = 50; // Adjust based on your desired sensitivity
        const SHAKE_COOLDOWN = 100; // Minimum time (in ms) between shakes
        const { x, y, z } = accelerationIncludingGravity;
        const magnitude = Math.sqrt(x * x + y * y + z * z);
    
        const now = Date.now();
        if (magnitude > SHAKE_THRESHOLD && now - lastShakeTimestamp > SHAKE_COOLDOWN) {
            console.log("[handleDeviceMotion] Shake detected!");
            setLastShakeTimestamp(now);
            sendMessage("UnityToWeb", "IncreaseShakeCounter", "");
        }
    };
    
    const OnUnityEnableShake = async (json) => {
        try {
            if (typeof DeviceMotionEvent !== "undefined" && typeof DeviceMotionEvent.requestPermission === "function") {
                const permission = await DeviceMotionEvent.requestPermission();
                if (permission !== "granted") {
                    console.warn("Motion event permission not granted");
                    return;
                }
            }
            console.log("[OnUnityEnableShake] Motion event permission granted or not required");
            window.addEventListener("devicemotion", handleDeviceMotion);
        } catch (error) {
            console.error("Error enabling shake detection:", error);
        }
    };
    
    const OnUnityDisableShake = (json) => {
        console.log("[OnUnityDisableShake] Removing shake event listener");
        window.removeEventListener("devicemotion", handleDeviceMotion);
    };

    const onUnityRequestPlayerInfo = (json) => {
        if (isLoggedIn()) {
            const playerInfo = { 
                "id" : auth.currentUser.uid,
                "email" : auth.currentUser.email,
                "data" : auth.currentUser, 
                "isGuest" : false,
                "cached_joincode" : localStorage.getItem("SavedJoinCode"),
                "username" : auth.currentUser.displayName,
                "profilePicURL": auth.currentUser.photoURL,
                "version" : GetVersionNum(),
                "autoplay" : localStorage.getItem("UseAutoPlay") === 'true',
                "network_env" : localStorage.getItem("network_env"),
            };
            sendMessage('UnityToWeb', 'ProcessWebConfig', JSON.stringify(playerInfo)); 
        }else{
            const playerInfo = { 
                "id" : localStorage.getItem('guestID'),
                "isGuest" : true,
                "cached_joincode" : localStorage.getItem("SavedJoinCode"),
                "version" : GetVersionNum(),
                "autoplay" : localStorage.getItem("UseAutoPlay") === 'true',
                "network_env" : localStorage.getItem("network_env"),
            };
            sendMessage('UnityToWeb', 'ProcessWebConfig', JSON.stringify(playerInfo)); 
        }   
    };

    const onUnityToggleFullscreen = (json) => {
        var value = JSON.parse(json);
        console.log(`[onUnityToggleFullscreen] unity requested to toggle fullscreen with value: ${value}`);
        requestFullscreen(value);
    };

    // This is called in app.js dunno if i also need it here
    useEffect(() => {
        let wakeLock = null;

        const requestWakeLock = async () => {
            try {
                wakeLock = await navigator.wakeLock.request('screen');
                console.log('[requestWakeLock] Wake Lock activated');
            } catch (error) {
                console.warn('[requestWakeLock] Failed to activate Wake Lock:', error);
            }
        };

        // Request wake lock when component mounts
        requestWakeLock();

        // Release wake lock when component unmounts
        return () => {
            if (wakeLock !== null) {
                wakeLock.release();
                console.log('Wake Lock released');
            }
        };
    }, []);

    useEffect(() => {
        console.log(`[GamePage] isLoaded has changed: ${isLoaded}`);
        if (isLoaded) {
            console.log('[GamePage] Unity has completed loading');
            if (!initializedRef.current) {
                // Ensure that initialization occurs only once
                console.log('[GamePage.UnityAttachment] Attempting to attach rmbclient to unity');
                
                // Attach Unity to Javscript Event Listeners
                addEventListener("UnityLogOut", onUnityLogOut);
                addEventListener("UnityRequestWebConfig", onUnityRequestPlayerInfo);
                addEventListener("UnityCopyToClipboard", onUnityCopyToClipboard);
                addEventListener("UnityVibrate", onUnityVibrate);
                addEventListener("UnityVibratePattern", onUnityVibratePattern);
                addEventListener("UnityToggleFullscreen", onUnityToggleFullscreen);
                addEventListener("UnityDeleteAccount", onUnityDeleteAccount);
                addEventListener("UnityUpdateJoincode", onUnityUpdateJoincode);
                addEventListener("UnityEnableShake", OnUnityEnableShake);
                addEventListener("UnityDisableShake", OnUnityDisableShake);

                // Finished attaching functions
                console.log('[GamePage.UnityAttachment] Finished attaching rmbclient to unity');
                
                // Finished setup
                initializedRef.current = true;
                console.log('[GamePage.UnityAttachment] completed unity setup');
                
                // Pass playerInfo
                onUnityRequestPlayerInfo("");
            }
        }

        return () => {
            console.log(`[GamePage.UnityAttachment] exiting with ${initializedRef.current}`);
            if (initializedRef.current) {
                // Put all clean up under logic here (if needed)
                removeEventListener("UnityLogOut", onUnityLogOut);
                removeEventListener("UnityRequestPlayerInfo", onUnityRequestPlayerInfo);
                removeEventListener("UnityCopyToClipboard", onUnityCopyToClipboard);
                removeEventListener("UnityVibrate", onUnityVibrate);
                removeEventListener("UnityVibratePattern", onUnityVibratePattern);
                removeEventListener("UnityToggleFullscreen", onUnityToggleFullscreen);
                removeEventListener("UnityDeleteAccount", onUnityDeleteAccount);
                removeEventListener("UnityUpdateJoincode", onUnityUpdateJoincode);
                removeEventListener("UnityEnableShake", OnUnityEnableShake);
                removeEventListener("UnityDisableShake", OnUnityDisableShake);
                OnUnityDisableShake();
                console.log('[GamePage.UnityAttachment] Cleared all attachments from rmbclient to unity');
                initializedRef.current = false;
            }
        };
    }, [isLoaded]);

    return (
        <Fragment>
            <div className="unity-container" ref={unityContainerRef}>
                <Unity
                    unityProvider={unityProvider}
                    className="unity-content"
                    ref={unityCanvasRef}
                    style={{ visibility: isLoaded ? "visible" : "hidden" }}
                />
            </div>

            {!isLoaded && (
                <div>
                    <div className="center center-boarder">
                        <div class="bottle-loader">
                            <div class="bubble"></div>
                            <div class="bubble"></div>
                            <div class="bubble"></div>
                        </div>
                        <br></br>
                        <p>Loading... {Math.round(loadingProgression * 100)}%</p>
                    </div>
                    <CancelButton onClick={onUnityLogOut}></CancelButton>
                </div>
            )}
        </Fragment>
    );
};
export default GamePage;